import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "components/seo"
import Layout from "components/layout"
import { GlobalCover } from "components/global/cover/cover"

import { CommissionersAndDirectorsList } from "components/pages/investor/commissioners-directors/commissioners-directors"

const CommissionersAndDirectors = ({ path, pageContext, location }) => {
  const data = useStaticQuery(GET_CnD_DETAILS)
  const lang = pageContext.langKey

  const pageData = lang === "en"
    ? data.wordPress.page.commDirectorsPage
    : data.wordPress.page.translation.commDirectorsPage
    || data.wordPress.page.commDirectorsPage

  const { seo, backgroundColor } = lang === "en"
    ? data.wordPress.page
    : data.wordPress.page.translation
    || data.wordPress.page

  const bgColor = backgroundColor?.backgroundColor?.color
  const SEODefaultImg = pageData.cover.image.sourceUrl


  return (
    <Layout path={path} theme={bgColor} lang={lang}>
      <SEO
        title={seo.title}
        desc={seo.metaDesc}
        fbImg={SEODefaultImg}
        fbTitle={seo.opengraphTitle}
        fbDesc={seo.opengraphDescription}
        url={location.href}
        twitterTitle={seo.twitterTitle}
        twitterDesc={seo.twitterDescription}
        twitterImg={SEODefaultImg}
      />
      <GlobalCover data={pageData.cover} theme={bgColor} breadcrumb={path} />
      <CommissionersAndDirectorsList commissioners={pageData.commissioners} directors={pageData.directors} />
    </Layout>
  )
}

export default CommissionersAndDirectors

const GET_CnD_DETAILS = graphql`
query CnD {
    wordPress {
      page(id: "commissioners-directors", idType: URI) {
        backgroundColor {
          backgroundColor {
            color
          }
        }
        seo {
          title
          metaDesc
          twitterTitle
          twitterDescription
          opengraphTitle
          opengraphDescription
        }
        title
        slug
        commDirectorsPage {
          cover {
            title
            image {
              sourceUrl
              altText
            }
          }
          directors {
            title
            list {
              title
              label
              image {
                sourceUrl
                altText
              }
            }
          }
          commissioners {
            title
            list {
              title
              label
              image {
                sourceUrl
                altText
              }
            }
          }
        }
        translation(language: ID) {
          backgroundColor {
            backgroundColor {
              color
            }
          }
          seo {
            title
            metaDesc
            twitterTitle
            twitterDescription
            opengraphTitle
            opengraphDescription
          }
          title
          slug
          commDirectorsPage {
            cover {
              title
              image {
                sourceUrl
                altText
              }
            }
            directors {
              title
              list {
                title
                label
                image {
                  sourceUrl
                  altText
                }
              }
            }
            commissioners {
              title
              list {
                title
                label
                image {
                  sourceUrl
                  altText
                }
              }
            }
          }
        }
      }
    }
  }
`