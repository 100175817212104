import React from "react"

import { NavTabs, TabContent, TabPane } from "components/anti/tabs/tabs"
import { Card } from "components/anti/card/card"
import { useScrollAnim } from "src/components/hooks/hooks"


export const CommissionersAndDirectorsList = ({ commissioners, directors }) => {
    const [trigger, anim] = useScrollAnim()

    return (
        <section className="py-main sc-cnd-list" ref={trigger}>
            <div className="container">
                <NavTabs
                    data={[
                        { name: commissioners.title, target: "commissioners" },
                        { name: directors.title, target: "directors" },
                    ]}
                    className="cnd-tabs justify-content-center"
                />
                <TabContent>
                    <TabPane id="commissioners" className="active">
                        <h2 className={`py-4 ${anim(1)}`}>{commissioners.title}</h2>
                        <div className="row row-3">
                            {commissioners.list.map((person, i) => {
                                return (
                                    <div className="col-6 col-md-2">
                                        <Card
                                            variant="boxless"
                                            img={person.image.sourceUrl}
                                            imgRatio="r-3-4"
                                            title={person.title}
                                            text={person.label}
                                            titleClassName="h5"
                                            cardBodyClassName="px-0"
                                            className={`${anim(2 + i)}`}
                                            key={i}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </TabPane>
                    <TabPane id="directors">
                        <h2 className={`py-4 ${anim(1)}`}>{directors.title}</h2>
                        <div className="row row-3">
                            {directors.list.map((person, i) => {
                                return (
                                    <div className="col-6 col-md-2">
                                        <Card
                                            variant="boxless"
                                            img={person.image.sourceUrl}
                                            imgRatio="r-3-4"
                                            title={person.title}
                                            text={person.label}
                                            titleClassName="h5"
                                            cardBodyClassName="px-0"
                                            className={`${anim(2 + i)}`}
                                            key={i}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </TabPane>
                </TabContent>
            </div>
        </section>
    )
}